









import { Vue, Component } from 'vue-property-decorator'
import VPlusLogo from '~components/assets/VPlusLogo.vue'

@Component({
  components: {
    VPlusLogo,
  },
})
export default class VPlusWidget extends Vue {}
